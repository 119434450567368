import React from 'react';
import { graphql } from 'gatsby';
import HeaderMain from '../components/HeaderMain';
import HeroSingle from '../components/pages/HeroSingle';
import Staff from '../components/homepage/Staff';
import GymIncluded from '../components/pages/GymIncluded';
import GymOwners from '../components/pages/GymOwners';
import Map from '../components/homepage/Map';
import SEO from '../components/seo';
import Footer from '../components/Footer';
import '../styles/app.scss';

const AboutPage = ({ data }) => {
    const { wordpressPage: post } = data;
    const test_title = data.wordpressPage.yoast_title;
    const new_seo_title = test_title.replace('&#039;', "'");

    return (
        <>
            <SEO
                title={new_seo_title}
                meta={data.wordpressPage.yoast_meta}
                description={
                    post.yoast_json_ld[0].wordpress__graph[0].description
                }
            />
            <HeaderMain />
            <HeroSingle pageTitle={post.title} />
            <GymOwners
                ownersTitle={post.acf.section_about_title}
                ownersContent={post.acf.section_about_content}
                ownersGallery={post.acf.owner_gallery}
            />
            <GymIncluded
                gymIncludesTitle={post.acf.gym_includes_title}
                gymIncludesContent={post.acf.gym_includes_content}
                gymIncludesGallery={post.acf.gym_includes_gallery}
            />
         {/*   <div className="videoWrapper explorespacediv">
                <iframe
                    src="https://my.matterport.com/show/?m=TFJogDJEpT4"
                    frameBorder="0"
                    allowFullScreen
                    allow="vr"
                    title="vr preview"
                ></iframe>
            </div>  */}
            <Staff />
            <Map />
            <Footer />
        </>
    );
};

export default AboutPage;

export const pageQuery = graphql`
    query($id: String!) {
        wordpressPage(id: { eq: $id }) {
            id
            title
            slug
            excerpt
            acf {
                section_about_content
                section_about_title
                owner_gallery {
                    id
                    localFile {
                        childImageSharp {
                            fluid {
                                srcWebp
                                src
                            }
                        }
                    }
                }
                gym_includes_title
                gym_includes_content
                gym_includes_gallery {
                    id
                    localFile {
                        publicURL
                        childImageSharp {
                            fluid {
                                src
                                srcWebp
                            }
                        }
                    }
                }
            }
            yoast_meta {
                name
                property
            }
            yoast_title
            yoast_json_ld {
                wordpress__graph {
                    description
                }
            }
        }
    }
`;
